import { useAuthRefresh } from 'api/auth'
import { JobList } from 'ui/components/job-list'
import { SiteLayout } from 'ui/components/site-layout'
import { Job } from 'api/types'
import { jobService, useJobs } from 'api/job'
import type { GetStaticProps } from 'next'
import { JobSearch } from 'ui/components/job-search'
import { useState } from 'react'
import { RefreshIcon } from '@heroicons/react/solid'

interface HomeProps {
  jobs: Job[]
}

export default function Home(props: HomeProps) {
  const [results, setResults] = useState([])
  const [usingSearch, setUsingSearch] = useState(false)
  const [loading, setLoading] = useState(false)

  useAuthRefresh()
  const { data: initialJobs = props.jobs } = useJobs(
    { limit: 50, sort: '-created_at' },
    {
      fallbackData: props.jobs
    }
  )

  return (
    <SiteLayout>
      <h1 className='container text-4xl font-bold italic pt-15 leading-tight md:text-6xl md:leading-tight lg:text-8xl lg:leading-tight'>
        Kick-A$$ jobs for
        <br />
        Kick-A$$ people.
      </h1>
      <div className='flex flex-col space-y-15 md:space-y-20'>
        <JobSearch setResults={setResults} usingSearch={setUsingSearch} setLoading={setLoading} />
        {loading ? (
          <RefreshIcon className='animate-spin mx-auto w-1/12 text-primary' aria-hidden='true' />
        ) : usingSearch ? (
          <JobList jobs={results} />
        ) : (
          <>
            <JobList jobs={initialJobs} title='Recent Jobs' />
          </>
        )}
      </div>
    </SiteLayout>
  )
}

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const jobs = await jobService.list({ limit: 50, sort: '-created_at' }).catch(() => [])
  return {
    props: { jobs },
    revalidate: 1
  }
}
